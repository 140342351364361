import { gql } from '@apollo/client';

export const POST_TRADE = gql`
    mutation MyMutation(
        $commission: numeric,
        $customer_id: uuid,
        $destination_currency: String,
        $fees: numeric,
        $fees_currency: String, 
        $provider_id: uuid,
        $quantity: numeric,
        $price: numeric,
        $ref_id: String,
        $side: String,
        $source_currency: String,
        $trade_source: String,
        $type: String,
        $value: numeric,
        $status: String,
        $transaction_updated_at: timestamptz,
        $transaction_created_at: timestamptz,
        $account_from_id: uuid,
        $account_to_id: uuid,
        $category: String,
    ) {
        insert_transactions_one(object: {
            commission: $commission,
            customer_id: $customer_id,
            destination_currency: $destination_currency,
            fees: $fees, 
            fees_currency: $fees_currency,
            provider_id: $provider_id,
            quantity: $quantity,
            price: $price,
            ref_id: $ref_id,
            side: $side,
            source_currency: $source_currency, 
            trade_source: $trade_source,
            type: $type, 
            value: $value,
            status: $status,
            transaction_updated_at: $transaction_updated_at,
            transaction_created_at: $transaction_created_at,
            account_from_id: $account_from_id,
            account_to_id: $account_to_id,
            category: $category
        }) {
            id
        }
    }
`;
export const GET_CUSTOMER_ID = gql`
    query MyQuery($ref_id: String) {
            customers(where: {ref_id: {_eq: $ref_id}}) {
            id
        }
    }
`
export const GET_CLIENT_ID = gql`
    query MyQuery($ref_id: String) {
            accounts(where: {ref_id: {_eq: $ref_id}}) {
            id
        }
    }
`

export const DELETE_TRADE = gql`
    mutation MyMutation($ref_id: String) {
        delete_transactions(where: {ref_id: {_eq: $ref_id}}) {
            returning {
                ref_id
            }
        }
    }
`

export const INSERT_TRADING_ACCOUNT = gql`
    mutation MyMutation (
        $customer_id: uuid,
        $provider_id: uuid,
        $ref_id: String,
        $name: String,
    ) {
        insert_accounts_one(object: {
            customer_id: $customer_id, 
            ref_id: $ref_id, 
            provider_id: $provider_id,
            name: $name
        }) {
            id
        }
    }
`

export const UPDATE_SDM_TRADE_LAST_SYNC = gql`
    mutation MyMutation (
        $id: uuid,
        $last_sync: timestamptz
    ) {
        update_providers_by_pk(pk_columns: {
                id: $id
            }, 
            _set: {
                last_sync: $last_sync
            }
    ) {
        last_sync
    }
}
`