// src/ApolloClient.js
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import React from 'react';

const client = new ApolloClient({
    link: new HttpLink({
        uri: 'https://sdm.productshop.io/v1/graphql',
        headers: {
            'content-type': 'application/json',
            'x-hasura-admin-secret': 'sHEU/YqcNu+zyAqoxNHAgNkzzVDO1g9CYirDbZyaNNPpniyUcdl1cBYLraAQG81h'
        }
    }),
    cache: new InMemoryCache(),
});

const ApolloProviderWrapper = ({ children }) => (
    <ApolloProvider client={client}>
        {children}
    </ApolloProvider>
);

export default ApolloProviderWrapper;
