export const rates = {
    'BTC': {
        decimal: 8,
        'BTC/USD': 2,
        'BTC/CAD': 2,
        'BTC/EUR': 2,
        'BTC/GBP': 2,
        'BTC/USDT': 2,
        'BTC/USDC': 2,
        'BTC/USDT_TRC': 2,
        'BTC/ETH': 5,
    },
    'ETH': {
        decimal: 6,
        'ETH/USD': 2,
        'ETH/GBP': 2,
        'ETH/EUR': 2,
        'ETH/CAD': 2,
        'ETH/USDT': 2,
        'ETH/USDC': 2,
        'ETH/USDT_TRC': 2,
        'ETH/BTC': 5,
    },
    'ETH_AETH': {
        decimal: 6,
        'ETH_AETH/USD': 2,
        'ETH_AETH/GBP': 2,
        'ETH_AETH/EUR': 2,
        'ETH_AETH/CAD': 2,
        'ETH_AETH/USDT': 2,
        'ETH_AETH/USDC': 2,
        'ETH_AETH/BTC': 5,
    },
    'USDT': {
        decimal: 2,
        'USDT/USD': 5,
        'USDT/CAD': 5,
        'USDT/EUR': 5,
        'USDT/GBP': 5,
        'USDT/USDC': 5,
    },
    'USDT_TRC': {
        decimal: 2,
        'USDT_TRC/USD': 5,
        'USDT_TRC/CAD': 5,
        'USDT_TRC/EUR': 5,
        'USDT_TRC/GBP': 5,
        'USDT_TRC/USDC': 5,
    },
    'USDC_SOL': {
        decimal: 2,
        'USDC_SOL/USD': 5,
        'USDC_SOL/CAD': 5,
        'USDC_SOL/EUR': 5,
        'USDC_SOL/GBP': 5,
        'USDC_SOL/USDC': 5,
    },
    'USDC_POLYGON': {
        decimal: 2,
        'USDC_POLYGON/USD': 5,
        'USDC_POLYGON/CAD': 5,
        'USDC_POLYGON/EUR': 5,
        'USDC_POLYGON/GBP': 5,
        'USDC_POLYGON/USDC': 5,
    },
    'USDC_BSC': {
        decimal: 2,
        'USDC_BSC/USD': 5,
        'USDC_BSC/CAD': 5,
        'USDC_BSC/EUR': 5,
        'USDC_BSC/GBP': 5,
        'USDC_BSC/USDC': 5,
    },
    'USDC_ARB': {
        decimal: 2,
        'USDC_ARB/USD': 5,
        'USDC_ARB/CAD': 5,
        'USDC_ARB/EUR': 5,
        'USDC_ARB/GBP': 5,
        'USDC_ARB/USDC': 5,
    },
    'USDT_OMNI': {
        decimal: 2,
        'USDT_OMNI/USD': 5,
        'USDT_OMNI/CAD': 5,
        'USDT_OMNI/EUR': 5,
        'USDT_OMNI/GBP': 5,
        'USDT_OMNI/USDC': 5,
    },
    'USDT_BSC': {
        decimal: 2,
        'USDT_BSC/USD': 5,
        'USDT_BSC/CAD': 5,
        'USDT_BSC/EUR': 5,
        'USDT_BSC/GBP': 5,
        'USDT_BSC/USDC': 5,
    },
    'USDT_SOL': {
        decimal: 2,
        'USDT_SOL/USD': 5,
        'USDT_SOL/CAD': 5,
        'USDT_SOL/EUR': 5,
        'USDT_SOL/GBP': 5,
        'USDT_SOL/USDC': 5,
    },
    'USDC': {
        decimal: 2,
        'USDC/USD': 5,
        'USDC/GBP': 5,
        'USDC/EUR': 5,
        'USDC/CAD': 5,
        'USDC/USDT': 5,
        'USDC/USDT_TRC': 5

    },
    'AVAX': {
        decimal: 4,
        'AVAX/USD':4,
        'AVAX/GBP':4,
        'AVAX/EUR':4,
        'AVAX/CAD':4,
        'AVAX/USDT':4,
        'AVAX/USDC':4,
        'AVAX/ETH':8,
        'AVAX/BTC':8,
    },
    'BNB': {
        decimal: 4,
        'BNB/USD':4,
        'BNB/GBP':4,
        'BNB/EUR':4,
        'BNB/CAD':4,
        'BNB/USDT':4,
        'BNB/USDC':4,
        'BNB/ETH':8,
        'BNB/BTC':8,
    },
    'BNB_BSC': {
        decimal: 4,
        'BNB_BSC/USD':4,
        'BNB_BSC/GBP':4,
        'BNB_BSC/EUR':4,
        'BNB_BSC/CAD':4,
        'BNB_BSC/USDT':4,
        'BNB_BSC/USDC':4,
        'BNB_BSC/ETH':8,
        'BNB_BSC/BTC':8,
    },
    'ADA': {
        decimal: 4,
        'ADA/USD':4,
        'ADA/GBP':4,
        'ADA/EUR':4,
        'ADA/CAD':4,
        'ADA/USDT':4,
        'ADA/USDC':4,
        'ADA/ETH':8,
        'ADA/BTC':8,
    },
    'LINK': {
        decimal: 4,
        'LINK/USD':4,
        'LINK/GBP':4,
        'LINK/EUR':4,
        'LINK/CAD':4,
        'LINK/USDT':4,
        'LINK/USDC':4,
        'LINK/ETH':8,
        'LINK/BTC':8,
    },
    'DOGE': {
        decimal: 5,
        'DOGE/USD':5,
        'DOGE/GBP':5,
        'DOGE/EUR':5,
        'DOGE/CAD':5,
        'DOGE/USDT':5,
        'DOGE/USDC':5,
        'DOGE/ETH':8,
        'DOGE/BTC':8
    },
    'LTC': {
        decimal: 4,
        'LTC/USD':4,
        'LTC/GBP':4,
        'LTC/EUR':4,
        'LTC/CAD':4,
        'LTC/USDT':4,
        'LTC/USDC':4,
        'LTC/ETH': 8,
        'LTC/BTC': 8,
    },
    'PAX': {
        decimal: 4,
        'PAX/USD':4,
        'PAX/GBP':4,
        'PAX/EUR':4,
        'PAX/CAD':4,
        'PAX/USDT':4,
        'PAX/USDC':4,
        'PAX/ETH':8,
        'PAX/BTC':8,
    },
    'DOT': {
        decimal: 4,
        'DOT/USD':4,
        'DOT/GBP':4,
        'DOT/EUR':4,
        'DOT/CAD':4,
        'DOT/USDT':4,
        'DOT/USDC':4,
        'DOT/ETH':8,
        'DOT/BTC': 8
    },
    'SHIB': {
        decimal: 8,
        'SHIB/USD':8,
        'SHIB/GBP':8,
        'SHIB/EUR':8,
        'SHIB/CAD':8,
        'SHIB/USDT':8,
        'SHIB/USDC':8,
        'SHIB/ETH':12,
        'SHIB/BTC':12,
    },
    'MATIC': {
        decimal: 4,
        'MATIC/USD':4,
        'MATIC/GBP':4,
        'MATIC/EUR':4,
        'MATIC/CAD':4,
        'MATIC/USDT':4,
        'MATIC/USDC':4,
        'MATIC/ETH':8,
        'MATIC/BTC':8
    },
    'MATIC_POLYGON': {
        decimal: 4,
        'MATIC_POLYGON/USD':4,
        'MATIC_POLYGON/GBP':4,
        'MATIC_POLYGON/EUR':4,
        'MATIC_POLYGON/CAD':4,
        'MATIC_POLYGON/USDT':4,
        'MATIC_POLYGON/USDC':4,
        'MATIC_POLYGON/ETH':8,
        'MATIC_POLYGON/BTC':8
    },
    'SOL': {
        decimal: 4,
        'SOL/USD':4,
        'SOL/GBP':4,
        'SOL/EUR':4,
        'SOL/CAD':4,
        'SOL/USDT':4,
        'SOL/USDC':4,
        'SOL/USDT_TRC':4,
        'SOL/ETH':8,
        'SOL/BTC':8
    },
    'ATOM': {
        decimal: 4,
        'ATOM/USD':4,
        'ATOM/GBP':4,
        'ATOM/EUR':4,
        'ATOM/CAD':4,
        'ATOM/USDT':4,
        'ATOM/USDC':4,
        'ATOM/BTC':8,
        'ATOM/ETH':8,
    },
    'ATOM_COS': {
        decimal: 4,
        'ATOM_COS/USD':4,
        'ATOM_COS/GBP':4,
        'ATOM_COS/EUR':4,
        'ATOM_COS/CAD':4,
        'ATOM_COS/USDT':4,
        'ATOM_COS/USDC':4,
        'ATOM_COS/BTC':8,
        'ATOM_COS/ETH':8,
    },
    'XRP': {
        decimal: 4,
        'XRP/USD': 4,
        'XRP/GBP': 4,
        'XRP/EUR': 4,
        'XRP/CAD': 4,
        'XRP/USDT': 4,
        'XRP/USDC': 4,
        'XRP/USDT_TRC': 4,
        'XRP/BTC':8,
        'XRP/ETH':8,
    },
    'XTZ': {
        decimal: 4,
        'XTZ/USD': 4,
        'XTZ/GBP': 4,
        'XTZ/EUR': 4,
        'XTZ/CAD': 4,
        'XTZ/USDT': 4,
        'XTZ/USDC': 4,
        'XTZ/BTC':8,
        'XTZ/ETH':8,
    },
    'SUI': {
        decimal: 4,
        'SUI/USD': 4,
        'SUI/GBP': 4,
        'SUI/EUR': 4,
        'SUI/CAD': 4,
        'SUI/USDT': 4,
        'SUI/USDC': 4,
        'SUI/BTC':8,
        'SUI/ETH':8,
    },
    'SEI': {
        decimal: 4,
        'SEI/USD': 4,
        'SEI/GBP': 4,
        'SEI/EUR': 4,
        'SEI/CAD': 4,
        'SEI/USDT': 4,
        'SEI/USDC': 4,
        'SEI/BTC':8,
        'SEI/ETH':8,
    },
    'STX': {
        decimal: 4,
        'STX/USD': 4,
        'STX/GBP': 4,
        'STX/EUR': 4,
        'STX/CAD': 4,
        'STX/USDT': 4,
        'STX/USDC': 4,
        'STX/BTC':8,
        'STX/ETH':8,
    },
    'ORDI': {
        decimal: 4,
        'ORDI/USD': 4,
        'ORDI/GBP': 4,
        'ORDI/EUR': 4,
        'ORDI/CAD': 4,
        'ORDI/USDT': 4,
        'ORDI/USDC': 4,
        'ORDI/BTC':8,
        'ORDI/ETH':8,
    },
    'OP': {
        decimal: 4,
        'OP/USD': 4,
        'OP/GBP': 4,
        'OP/EUR': 4,
        'OP/CAD': 4,
        'OP/USDT': 4,
        'OP/USDC': 4,
        'OP/BTC':8,
        'OP/ETH':8,
    },
    'ARB': {
        decimal: 4,
        'ARB/USD': 4,
        'ARB/GBP': 4,
        'ARB/EUR': 4,
        'ARB/CAD': 4,
        'ARB/USDT': 4,
        'ARB/USDC': 4,
        'ARB/BTC':8,
        'ARB/ETH':8,
    },
    'APT': {
        decimal: 4,
        'APT/USD': 4,
        'APT/GBP': 4,
        'APT/EUR': 4,
        'APT/CAD': 4,
        'APT/USDT': 4,
        'APT/USDC': 4,
        'APT/BTC':8,
        'APT/ETH':8,
    },
    'CRV': {
        decimal: 4,
        'CRV/USD': 4,
        'CRV/GBP': 4,
        'CRV/EUR': 4,
        'CRV/CAD': 4,
        'CRV/USDT': 4,
        'CRV/USDC': 4,
        'CRV/BTC':8,
        'CRV/ETH':8,
    },
    'FTM': {
        decimal: 4,
        'FTM/USD': 4,
        'FTM/GBP': 4,
        'FTM/EUR': 4,
        'FTM/CAD': 4,
        'FTM/USDT': 4,
        'FTM/USDC': 4,
        'FTM/BTC':8,
        'FTM/ETH':8,
    },
    "FTM_FANTOM": {
        decimal: 4,
        'FTM_FANTOM/USD': 4,
        'FTM_FANTOM/GBP': 4,
        'FTM_FANTOM/EUR': 4,
        'FTM_FANTOM/CAD': 4,
        'FTM_FANTOM/USDT': 4,
        'FTM_FANTOM/USDC': 4,
        'FTM_FANTOM/BTC':8,
        'FTM_FANTOM/ETH':8,
    },
    'TAO': {
        decimal: 4,
        'TAO/USD': 4,
        'TAO/GBP': 4,
        'TAO/EUR': 4,
        'TAO/CAD': 4,
        'TAO/USDT': 4,
        'TAO/USDC': 4,
        'TAO/BTC':8,
        'TAO/ETH':8,
    },
    'AAVE': {
        decimal: 4,
        'AAVE/USD': 4,
        'AAVE/GBP': 4,
        'AAVE/EUR': 4,
        'AAVE/CAD': 4,
        'AAVE/USDT': 4,
        'AAVE/USDC': 4,
        'AAVE/BTC':8,
        'AAVE/ETH':8,
    },
    'UNI': {
        decimal: 4,
        'UNI/USD': 4,
        'UNI/GBP': 4,
        'UNI/EUR': 4,
        'UNI/CAD': 4,
        'UNI/USDT': 4,
        'UNI/USDC': 4,
        'UNI/BTC':8,
        'UNI/ETH':8,
    },
    'TRX': {
        decimal: 5,
        'TRX/USD': 5,
        'TRX/GBP': 5,
        'TRX/EUR': 5,
        'TRX/CAD': 5,
        'TRX/USDT': 5,
        'TRX/USDT_TRC': 5,
        'TRX/USDC': 5,
        'TRX/BTC':8,
        'TRX/ETH':8,
    },
    'USD': {
        decimal: 2,
    },
    'CAD': {
        decimal: 2,
    },
    'EUR': {
        decimal: 2,
    },
    'GBP': {
        decimal: 2,
    }
}
