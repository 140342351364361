import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import ApolloProviderWrapper from './api/atlasGraphql';
const HOST = process.env.REACT_APP_HOST;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const AUDIENCE = process.env.REACT_APP_AUDIENCE;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <ApolloProviderWrapper>
    <BrowserRouter>
      <Auth0Provider
          domain={HOST}
          clientId={CLIENT_ID}
          redirectUri={`${window.location.origin}/?redirect=true`}
          audience={AUDIENCE}

          // redirectUri={'http://trade-sandbox.sdm.co/'}
      >
        <App />
      </Auth0Provider>
    </BrowserRouter>
    </ApolloProviderWrapper>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
