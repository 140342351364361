import React, { useState } from 'react';
import TradeTicketGenerator from '../TradeTicketGenerator';
import VoiceChatLPExecution from '../VoiceChatLPExecution';
import { Button, Box, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
function MergedTrades({curUser, token, showTable}) {
    console.log({curUser, token, showTable});
    const [expandTradeTicketGenerator, setExpandTradeTicketGenerator] = useState(false)
    const [expandVoiceChat, setExpandVoiceChat] = useState(false);
    const [showVoiceChat, setShowVoiceChat] = useState(false)
    /**
     * Checks if the viewport width is larger than the specified breakpoint.'
     */
    const isLargerThanMd = useMediaQuery('(min-width: 768px)');
    const handleExpandTradeTicketGenerator = () => {
        setExpandTradeTicketGenerator(!expandTradeTicketGenerator)
    }
    const handleExpandVoiceChatLP = () => {
        setExpandVoiceChat(!expandVoiceChat)
    }
    const handleExpandVoiceChatLPLarge = () => {
        setShowVoiceChat(!showVoiceChat)
    }
    return (
        <>
            {isLargerThanMd? <Box sx={{ width: { xs: '90%', md: '98%' }, margin: { xs: 'auto', md: 1}, marginBottom: {xs: 2, md: 0}}}>
                <Button onClick={handleExpandVoiceChatLPLarge} sx={{width: '100%', borderRadius: 0,}}>
                    {showVoiceChat? 'Hide' : 'Show'} Voice Chat LP
                </Button>
            </Box> : null}
            <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                paddingBottom: { xs: 20},
                // margin: 'auto'
            }}
        >
            {!isLargerThanMd && <Box sx={{ width: { xs: '90%' }, margin: { xs: 'auto', md: 1}, marginBottom: {xs: 2, md: 0}}}>
                <Button sx={{width: '100%'}} variant="contained" startIcon={expandTradeTicketGenerator? <ExpandLessIcon/>:<ExpandMoreIcon />} onClick={() => handleExpandTradeTicketGenerator()}>
                    {expandTradeTicketGenerator? 'Hide': 'Show'} Trade ticket generator
                </Button>
            </Box>}
            {expandTradeTicketGenerator || isLargerThanMd ? <Box sx={{ width: showVoiceChat? { xs: '90%', md: '55%' }: { xs: '90%', md: '99%' }, margin: { xs: 'auto', md: 1}, marginBottom: {xs: 4, md: 0}}}>
                <TradeTicketGenerator curUser={curUser} token={token} showTable={true && isLargerThanMd} isExpand={!showVoiceChat}/>
            </Box> : null}
            {!isLargerThanMd && expandTradeTicketGenerator && <Box sx={{ width: { xs: '90%' }, margin: { xs: 'auto', md: 1}, marginBottom: {xs: 2, md: 0}}}>
                <Button sx={{width: '100%'}} variant="contained" startIcon={expandTradeTicketGenerator? <ExpandLessIcon/>:<ExpandMoreIcon />} onClick={() => handleExpandTradeTicketGenerator()}>
                    {expandTradeTicketGenerator? 'Hide': 'Show'} Trade ticket generator
                </Button>
            </Box>}
            {!isLargerThanMd && <Box sx={{ width: { xs: '90%' }, margin: { xs: 'auto', md: 1}, marginBottom: {xs: 2, md: 0}}}>
                <Button sx={{width: '100%'}} variant="outlined" startIcon={expandVoiceChat? <ExpandLessIcon/>:<ExpandMoreIcon />} onClick={() => handleExpandVoiceChatLP()}>
                    {expandVoiceChat? 'Hide' : 'Show'} Voice Chat LP
                </Button>
            </Box>}
            {/* side button to show/hide voice chat  */}
            {expandVoiceChat || isLargerThanMd? isLargerThanMd && !showVoiceChat? null : <Box sx={{ width: { xs: '90%', md: '43%' }, margin: { xs: 'auto', md: 1}, marginBottom: {xs: 2, md: 0}}}>
                <VoiceChatLPExecution curUser={curUser} token={token} showTable={true && isLargerThanMd}/>
            </Box> : null}
            {!isLargerThanMd && expandVoiceChat && <Box sx={{ width: { xs: '90%' }, margin: { xs: 'auto', md: 1}, marginBottom: {xs: 2, md: 0}}}>
                <Button sx={{width: '100%'}} variant="outlined" startIcon={expandVoiceChat? <ExpandLessIcon/>:<ExpandMoreIcon />} onClick={() => handleExpandVoiceChatLP()}>
                    {expandVoiceChat? 'Hide' : 'Show'} Voice Chat LP
                </Button>
            </Box>}
        </Box>
        </>
        
    );
}

export default MergedTrades;